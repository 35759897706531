import React from "react";

import "../PrivacyPolicy/PrivacyPolicy.css";

function TermsAndConditions() {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-head">
        <h1>Terms And Conditions</h1>
      </div>
      <div className="privacy-policy-main-content">
        <p>
          By visiting, accessing and utilizing our website &nbsp;
          <a href="www.cleverclicks.ae">www.cleverclicks.ae</a> &nbsp;, you
          agree to adhere and be bound by the Terms & Conditions described
          below. If you don’t agree to accept the terms & condition of use, it
          is requested to avoid further use of the website. The content and
          materials present on the website are protected by the trademark law
          and copyright.
        </p>
      </div>
      <div className="privacy-policy-main-content">
        <h2>Use of the Website </h2>
        <p>
          License: Clever Clicks offers all clients and visitors a limited-time,
          non-exclusive, revocable license to use and access the website for
          non-commercial and informational purpose.
          <p>
            Prohibited Activities: By using the website, you agree to avoid
            involving in any of the following activities:
          </p>
          <ul>
            <li>
              Using the site for any kind of unethical and illegal purposes.
            </li>
            <li>
              Trying to gain any kind of unlawful access to the site or to its
              success
            </li>{" "}
            <li>Spreading harmful malware or codes</li>{" "}
          </ul>
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Intellectual Property</h2>
        <p>
          <ul>
            <li>
              Copyright: All the content and materials on the website including
              graphics, text, software, and logos are the property of Clever
              Clicks and they are protected by the copyright laws.
            </li>
            <li>
              Trademarks: All logos and trademarks used across the website are
              the property of Clever Clicks or the respective owners.
            </li>{" "}
          </ul>
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Privacy Policy</h2>
        <p>
          The usages of the website is solely governed and regulated by the
          Privacy Policy.
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Links to 3rd Party Websites</h2>
        <p>
          There are many links of the third party sites across the website of
          Clever Clicks. However, Clever Clicks is not liable for any practices
          or content of those 3rd party websites. Clicking those links or
          accessing those 3rd party websites should be done at your own risk.
          Clever Clicks will not be responsible for any kind of loss incurred by
          accessing those websites or 3rd party links.
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Disclaimer of Warranties</h2>
        <p>
          <ul>
            <li>
              The Website is offered in its current state, with no guarantees of
              any kind, including, but not limited to, implied warranties of
              merchantability, fitness for a specific purpose, or
              non-infringement.
            </li>
            <li>
              Clever Clicks cannot ensure the accuracy, entirety, or reliability
              of any information on the Website.
            </li>{" "}
          </ul>
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Limitation of Liability </h2>
        <p>
          Under no circumstances will Clever Clicks be held responsible for any
          direct, indirect, special, incidental or consequential damages
          resulting from or related to your utilization or inability to use the
          Website.
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Refund Policy</h2>
        <p>
          At Clever Clicks, our commitment to client satisfaction is paramount.
          Therefore, once a payment is made for our services and the contract is
          signed, we strictly adhere to a policy to accommodate any evolving
          needs of clients by offering the option to replace the initially
          contracted services with alternatives from our service portfolio,
          instead of a refund. We kindly urge our clients to thoroughly assess
          their requirements before finalizing their engagement with Clever
          Clicks. Our dedicated team is readily available to address any
          questions or concerns you may have related to the refund policy.
        </p>
      </div>{" "}
      <div className="privacy-policy-main-content">
        <h2>Changes of Terms & Conditions</h2>
        <p>
          Clever Clicks hold the rights to modify and change the terms and
          conditions anytime with or without notification. It is the
          responsibility of all visitors and users to closely monitor the
          changes or read the updated terms & conditions periodically.
          <p>
            Anyone having doubts, concerns and questions related to terms and
            conditions may contact us at our email ID &nbsp;
            <a href="mailto:info@cleverclicks.ae">info@cleverclicks.ae</a>.
          </p>
        </p>
      </div>{" "}
    </div>
  );
}

export default TermsAndConditions;
