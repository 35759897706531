const companyData = {
  companyAddress: "A2 Khushnuma Complex, Meera Bai Marg, Lucknow, India",
  companyPhone: "+919919444434",
  companyEmail: "info@cleverclicks.ae",
  companyFacebook: "https://www.facebook.com/profile.php?id=61552653639237",
  companyInsta: "https://www.instagram.com/thecleverclicks/",
  companyLinkedIn:
    "https://www.linkedin.com/company/101043731/admin/feed/posts/",
};

export default companyData;
